export const API_URLS = {
  getNonce: "/getNonce",
  projectlist: "/projectlist",
  Tasklistbyproject: "/Tasklistbyproject",
  TaskdetailTaskid: "/TaskdetailTaskid",
  getDashboard: "/getDashboard",
  UserSocialAction: "/UserSocialAction",
  getTaskhistory: "getTaskhistory",
  claimPoint: "/claimPoint",
  getclaimhistory: "/getclaimhistory",
  TelegramLogin: "/TelegramLogin",
  getTopLeader: "/getTopLeader",
  UserUpdateProfile: "/UserUpdateProfile",
};
