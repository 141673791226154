import React, { useEffect, useState } from "react";
import image from "../../images/Bdt-coin.png";
import image2 from "../../images/Bdt-coin.png";
import axiosInstance1 from "../../Config/axios1";
import { API_URLS } from "../../Config/apiUrls";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "../../Config/axios";
import { useAccount } from "wagmi";
import { useAppKit } from "@reown/appkit/react";

export default function Taskdetail() {
  const { id } = useParams();
  const { open } = useAppKit();
  const { isConnected } = useAccount();
  const [isLoading, setIsLoading] = useState(false);

  const token = localStorage.getItem("token");
  const [getUserdata, setUserData] = useState();
  const [getTask, setGetTask] = useState();
  const getTaskSingale = () => {
    setIsLoading(true);
    axiosInstance1
      .get(API_URLS.TaskdetailTaskid + `/${id}`)
      .then((res) => {
        setGetTask(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const TaskFunction = (id) => {
    axiosInstance
      .post(API_URLS.UserSocialAction, { taskid: id })
      .then((res) => {
        toast.success(res.data.message);
        if (res.data.actionUrl) {
          window.open(`${res.data.actionUrl}`, "_blank");
        }
        getTaskSingale();
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getDashboard = () => {
    setIsLoading(true);
    axiosInstance
      .get(API_URLS.getDashboard)
      .then((res) => {
        setUserData(res.data.userdata);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getDashboard();
  }, []);

  useEffect(() => {
    if (id) {
      getTaskSingale();
    }
  }, [id]);

  return (
    <div>
      {isLoading ? (
        <div className="loader-mask">
          <div className="loader">
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <>
          <section class="lg:w-2/3 py-16 md:py-20 lg:py-28 bg-black mx-auto">
            <div class=" mt-75 bg-black text-white min-h-screen p-6 font-sans">
              {/* <!-- Header Section --> */}
              <div class="flex items-center gap-3 mb-4">
                <img src={image} alt="Logo" class="w-10 h-10 rounded-full" />
                <div>
                  <h1 class="text-lg font-semibold">BDT</h1>
                  <span class="text-sm text-gray-400">+ Follow</span>
                </div>
                <span class="ml-auto text-sm text-gray-400">1893</span>
              </div>

              {/* <!-- Main Content --> */}
              <h2 class="text-2xl font-bold mb-2">{getTask?.projectTitle}</h2>
              <p class="text-gray-300 text-sm mb-4">
                {getTask?.projectDescription}
              </p>

              {/* <!-- Time and Participants --> */}
              <div class="flex flex-wrap items-center gap-2 text-gray-400 text-sm mb-4">
                <div class="flex  flex-wrap items-center gap-1">
                  <div class="flex items-center gap-2">
                    {/* <!-- Overlapping Circles --> */}
                    <a href="#">
                      <div class="flex -space-x-2">
                        <div class="w-8 h-8 rounded-full bg-gradient-to-r from-yellow-400 to-pink-500 border-2 border-black"></div>
                        <div class="w-8 h-8 rounded-full bg-gradient-to-r from-blue-500 to-purple-500 border-2 border-black"></div>
                        <div class="w-8 h-8 rounded-full bg-gradient-to-r from-purple-600 to-yellow-300 border-2 border-black"></div>
                        <div class="w-8 h-8 rounded-full bg-blue-900 border-2 border-black flex items-center justify-center text-white text-sm font-semibold">
                          ...
                        </div>
                      </div>
                    </a>
                    {/* <!-- Number --> */}
                    <span class="text-white text-sm font-medium">142</span> |
                  </div>
                </div>
                <span>{getTask?.created_at}</span>
              </div>

              <div class="border-slate-800 border p-4 rounded-lg flex flex-col md:flex-row justify-between items-center mb-4">
                <div class="mb-4 md:mb-0">
                  <h3 class="font-semibold text-sm">{getTask?.taskTitle}</h3>
                  <p class="text-gray-400 text-sm">
                    {getTask?.taskdescribtion}
                  </p>
                </div>
                {isConnected ? (
                  token ? (
                    getUserdata?.twitterLinkstatus === "0" ? (
                      <span class="ml-auto">
                        <Link
                          to={`https://poolliquidity.aquaeal.in/ApiV3/TwitterAuth/login?UserToken=${token}`}
                          class="bg-gradient-to-r from-cyan-500 to-blue-500 text-white px-4 py-2 rounded-lg font-semibold hover:bg-blue-700 transition duration-300 xs:w-full cursor-pointer"
                          target="_blank"
                        >
                          {getTask?.SubSubcatgeoryName}
                        </Link>
                      </span>
                    ) : getTask?.taskComplete ? (
                      <p class="bg-gradient-to-r from-cyan-500 to-blue-500 text-white px-4 py-2 rounded-lg font-semibold hover:bg-blue-700 transition duration-300 xs:w-full cursor-pointer">
                        Completed
                      </p>
                    ) : (
                      <p
                        onClick={() => TaskFunction(getTask?.taskiId)}
                        class="bg-gradient-to-r from-cyan-500 to-blue-500 text-white px-4 py-2 rounded-lg font-semibold hover:bg-blue-700 transition duration-300 xs:w-full cursor-pointer"
                      >
                        {getTask?.SubSubcatgeoryName}
                      </p>
                    )
                  ) : (
                    <p
                      class="bg-gradient-to-r from-cyan-500 to-blue-500 text-white px-4 py-2 rounded-lg font-semibold hover:bg-blue-700 transition duration-300 xs:w-full cursor-pointer"
                      onClick={() => toast.warn("Please sign in.")}
                    >
                      {getTask?.SubSubcatgeoryName}
                    </p>
                  )
                ) : (
                  <p
                    class="bg-gradient-to-r from-cyan-500 to-blue-500 text-white px-4 py-2 rounded-lg font-semibold hover:bg-blue-700 transition duration-300 xs:w-full cursor-pointer"
                    onClick={() => open()}
                  >
                    {getTask?.SubSubcatgeoryName}
                  </p>
                )}
              </div>

              {/* <!-- Requirements Section --> */}
              <p class="font-bold mb-2">Requirements</p>
              <a href="#">
                <div class="bg-zinc-900 hover:bg-zinc-800 p-4 rounded-lg flex items-center gap-4">
                  <span class="bg-gray-700 w-8 h-8 flex items-center justify-center rounded-full text-sm">
                    <img src={image2} alt="" />
                  </span>
                  <span>Galxe Web3 Score - Humanity Score</span>
                  <span class="ml-auto">
                    <i class="icon icon-cw"></i>
                  </span>
                </div>
              </a>
              <p class="font-bold mb-2 mt-6">Get 50 points</p>
              <a href="#">
                <div class="bg-zinc-900 hover:bg-zinc-800 p-4 rounded-lg flex items-center gap-4">
                  <span class="bg-gray-700 w-8 h-8 flex items-center justify-center rounded-full text-sm">
                    <img src={image2} alt="" />
                  </span>
                  <span>Galxe Web3 Score - Humanity Score</span>
                  <span class="ml-auto">
                    <i class="icon icon-cw"></i>
                  </span>
                </div>
              </a>
              <p class="font-bold mb-2 mt-6">Get {getTask?.taskpoint} points</p>
              <a href="#">
                <div class="bg-zinc-900 hover:bg-zinc-800 p-4 rounded-lg flex items-center gap-4">
                  <span class="bg-gray-700 w-8 h-8 flex items-center justify-center rounded-full text-sm">
                    <img src={image2} alt="" />
                  </span>
                  <span>Galxe Web3 Score - {getTask?.taskpoint} Points</span>
                  <span class="ml-auto">
                    <i class="icon icon-cw"></i>
                  </span>
                </div>
              </a>
            </div>
            <h2 class=" lg:mt-100 mt-75 text-2xl font-bold mb-2 text-center">
              Explore More
            </h2>
          </section>
          <section class=" lg:w-4/5 w-full  bg-black lg:px-7 px-4 pb-24 mx-auto">
            <div class="flex space-x-0 justify-between flex-wrap">
              {/* <!-- Box 1 --> */}
              <a href="#" class="block">
                <div class="bg-zinc-900 text-white p-4 rounded-lg shadow-lg max-w-sm sm:max-w-md md:max-w-lg lg:max-w-xl mx-auto mb-3">
                  <div class="flex justify-between items-center mb-2">
                    <span class="text-xs bg-gray-800 py-1 px-2 rounded-full">
                      Custom Reward
                    </span>
                    <div class="bg-gray-800 p-1 rounded-full">
                      <img src={image2} alt="Icon" class="w-5 h-5" />
                    </div>
                  </div>
                  <h2 class="text-xs font-semibold mb-2 text-center sm:text-left">
                    Mind Network Pivot | Citizen Z Program
                  </h2>
                  <p class="text-gray-400 text-sm mb-4 text-center sm:text-left">
                    324.31K participants
                  </p>
                  <div class="flex flex-col sm:flex-row sm:justify-between sm:items-center">
                    <div class="flex items-center space-x-2 justify-center sm:justify-start mb-4 sm:mb-0">
                      <div class="bg-green-700 p-2 rounded-lg">
                        <img src={image2} alt="Mind Network" class="w-6 h-6" />
                      </div>
                      <span class="text-gray-300 text-sm">Mind Network</span>
                    </div>
                    <div class="flex justify-center sm:justify-end">
                      <img
                        src="images/blog/blog01.jpg"
                        alt="Astronaut"
                        class="w-16 h-16 rounded-md"
                      />
                    </div>
                  </div>
                </div>
              </a>

              {/* <!-- Box 2 --> */}
              <a href="#" class="block">
                <div class="bg-zinc-900 text-white p-4 rounded-lg shadow-lg max-w-sm sm:max-w-md md:max-w-lg lg:max-w-xl mx-auto mb-3">
                  <div class="flex justify-between items-center mb-2">
                    <span class="text-xs bg-gray-800 py-1 px-2 rounded-full">
                      Custom Reward
                    </span>
                    <div class="bg-gray-800 p-1 rounded-full">
                      <img src={image2} alt="Icon" class="w-5 h-5" />
                    </div>
                  </div>
                  <h2 class="text-xs font-semibold mb-2 text-center sm:text-left">
                    Mind Network Pivot | Citizen Z Program
                  </h2>
                  <p class="text-gray-400 text-sm mb-4 text-center sm:text-left">
                    324.31K participants
                  </p>
                  <div class="flex flex-col sm:flex-row sm:justify-between sm:items-center">
                    <div class="flex items-center space-x-2 justify-center sm:justify-start mb-4 sm:mb-0">
                      <div class="bg-green-700 p-2 rounded-lg">
                        <img src={image2} alt="Mind Network" class="w-6 h-6" />
                      </div>
                      <span class="text-gray-300 text-sm">Mind Network</span>
                    </div>
                    <div class="flex justify-center sm:justify-end">
                      <img
                        src="images/blog/blog01.jpg"
                        alt="Astronaut"
                        class="w-16 h-16 rounded-md"
                      />
                    </div>
                  </div>
                </div>
              </a>

              {/* <!-- Box 3 --> */}
              <a href="#" class="block">
                <div class="bg-zinc-900 text-white p-4 rounded-lg shadow-lg max-w-sm sm:max-w-md md:max-w-lg lg:max-w-xl mx-auto mb-3">
                  <div class="flex justify-between items-center mb-2">
                    <span class="text-xs bg-gray-800 py-1 px-2 rounded-full">
                      Custom Reward
                    </span>
                    <div class="bg-gray-800 p-1 rounded-full">
                      <img src={image2} alt="Icon" class="w-5 h-5" />
                    </div>
                  </div>
                  <h2 class="text-xs font-semibold mb-2 text-center sm:text-left">
                    Mind Network Pivot | Citizen Z Program
                  </h2>
                  <p class="text-gray-400 text-sm mb-4 text-center sm:text-left">
                    324.31K participants
                  </p>
                  <div class="flex flex-col sm:flex-row sm:justify-between sm:items-center">
                    <div class="flex items-center space-x-2 justify-center sm:justify-start mb-4 sm:mb-0">
                      <div class="bg-green-700 p-2 rounded-lg">
                        <img src={image2} alt="Mind Network" class="w-6 h-6" />
                      </div>
                      <span class="text-gray-300 text-sm">Mind Network</span>
                    </div>
                    <div class="flex justify-center sm:justify-end">
                      <img
                        src="images/blog/blog01.jpg"
                        alt="Astronaut"
                        class="w-16 h-16 rounded-md"
                      />
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </section>
        </>
      )}
    </div>
  );
}
