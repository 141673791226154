import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import image from "../../images/slides/slidebg.jpg";
import image1 from "../../images/single-img-05.jpg";
import image2 from "../../images/Bdt-coin.png";
import image3 from "../../images/single-img-05.jpg";
import image4 from "../../images/chart.png";
import image5 from "../../images/blog/blog01.jpg";
import image6 from "../../images/bg-image/row-bgimage-7.jpg";
import video from "../../images/videobg2.mp4";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

export default function Home() {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    // Simulate an async operation, such as fetching data
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);
  return (
    <div>
      {isLoading && (
        <div className="loader-mask">
          <div className="loader">
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      <Header />
      <div class="video-container">
        <video autoPlay loop muted playsInline id="bg-video1">
          <source src={video} type="video/mp4" loading="lazy" />
        </video>

        <div class="video-overlay"></div>
        <div class="overlay-content">
          <h1 class="text-4xl md:text-7xl font-extrabold leading-tight mb-12 lg:whitespace-nowrap">
            Gold at the Core,
          </h1>
          <h1 class="text-4xl md:text-7xl font-extrabold leading-tight mb-12 lg:whitespace-nowrap">
            Blockchain at the Forefront
          </h1>
          <p class="text-lg md:text-xl max-w-3xl mx-auto">
            BDTCOIN: Backed by real gold, proving the Real-World Asset (RWA)
            concept and redefining digital finance.
          </p>
          <Link
            to="/airdrop"
            class="inline-block px-6 py-3 mt-6 bg-customOrange text-white font-semibold text-lg rounded-full hover:bg-bgairdrop transition-all"
          >
            Join the Gold-Backed Revolution
          </Link>
        </div>
      </div>

      {/* <!-- START decfoxSlider 1 REVOLUTION SLIDER 6.5.9 --> */}
      {/* <div
        className="relative h-screen bg-cover bg-center"
        style={{
          backgroundImage: `url(${image})`,
        }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="absolute inset-0 flex items-center justify-center text-center text-white px-4">
          <div className="space-y-6">
            <h1 className="text-4xl md:text-6xl font-extrabold leading-tight">
              Gold at the Core, Blockchain at the Forefront
            </h1>
            <p className="text-lg md:text-xl max-w-3xl mx-auto">
              BDTCOIN: Backed by real gold, proving the Real-World Asset (RWA)
              concept and redefining digital finance.
            </p>
            <a
              href="#cta"
              className="inline-block px-6 py-3 mt-6 bg-customOrange text-white font-semibold text-lg rounded-full hover:bg-bgairdrop transition-all"
            >
              Join the Gold-Backed Revolution
            </a>
          </div>
        </div>
      </div> */}
      {/* <!--site-main start--> */}
      <div className="site-main">
        <section className="about-section-03 py-20 lg:px-16 px:10">
          <div className="mx-auto lg:px-16 px:10">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
              <div className="space-y-6 space-x-6 px-8">
                <div className="section-title">
                  <div className="title-header">
                    <h3 className="text-gray-500 text-xl  mb-4">Welcome to</h3>
                    <h2 className="lg:text-6xl text-3xl font-extrabold text-gray-900 mb-4">
                      <span className="text-customOrange">Gold</span> Standard
                      Currency!
                    </h2>
                  </div>
                  <div className="title-desc mt-12">
                    <p className="text-gray-600">
                      BDTCOIN is crafted to serve both private and public
                      sectors, catering to a broad spectrum of applications. For
                      individual users, BDTCOIN provides a high level of
                      privacy, ensuring that personal transactions remain
                      confidential and secure from surveillance. In the public
                      domain, BDTCOIN can revolutionize how governmental and
                      non-governmental organizations handle transactions by
                      reducing bureaucratic delays and increasing transparency
                      where necessary. This dual utility makes BDTCOIN a
                      versatile digital currency, designed to meet diverse needs
                      and adapt to various scenarios, enhancing the efficiency
                      and security of digital exchanges across different spheres
                      of usage.
                    </p>
                    <h4 className="mt-4">Mission Statement</h4>
                    <p>
                      To create a financial ecosystem where digital currency is
                      backed by real-world assets like gold, proving the RWA
                      concept and instilling trust through tangible value.
                    </p>
                    <h4 className="mt-4">Our Vision</h4>
                    <p>
                      To lead the way in integrating digital currencies with
                      real-world assets, providing security and stability
                      through a gold-backed financial model.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="flex justify-center items-start flex-col lg:ml-7 px-8"
                style={{
                  maxWidth: "750px",
                  margin: "0 auto",
                }}
              >
                <img
                  className="w-full h-auto max-w-xs lg:max-w-md"
                  src={image1}
                  alt="Gold Standard Currency Image"
                />

                <h4 className="mt-4 mb-4">Values</h4>
                <p className="mb-4">
                  <span className="font-bold text-customOrange">
                    Stability:{" "}
                  </span>{" "}
                  The Currency of Confidence: Backed by tangible gold for
                  unparalleled reliability.
                </p>
                <p className="mb-4">
                  <span className="font-bold text-customOrange">
                    Innovation:{" "}
                  </span>{" "}
                  Revolutionizing finance with the synergy of blockchain and
                  Real-World Assets (RWAs).
                </p>
                <p className="mb-4">
                  <span className="font-bold text-customOrange">
                    Transparency:{" "}
                  </span>{" "}
                  Clear, secure, and traceable transactions, merging trust and
                  technology.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="md:py-5 py-4">
          <div className="flex items-center justify-center">
            <div
              className="w-full mx-auto text-center"
              style={{
                maxWidth: "1170px",
                backgroundImage: `url(${image6})`,

                backgroundSize: "cover",
                padding: "30px",
                borderRadius: "10px",
                margin: "0 auto",
              }}
            >
              <div className="text-center">
                <img
                  src={image2}
                  alt="BDTCOIN"
                  style={{
                    height: "130px",
                    width: "130px",
                    margin: "0 auto 25px",
                    backgroundColor: "#f0f0f0",
                  }}
                />
              </div>
              <h2 className="mb-0 lg:text-3xl text-xl drop-shadow-lg font-bold text-white !leading-tight sm:text-4xl md:text-[20px]   [text-shadow:0_0_rgba(0,0,0,0.1)]">
                Partner with BDTCOIN:{" "}
                <span className="font-medium">
                  Be Part of the Gold-Backed Revolution
                </span>
              </h2>
              <p>
                Collaborate with a platform that combines gold-backed stability
                with blockchain innovation, proving the Real-World Asset (RWA)
                concept.
              </p>
              <button className="bg-customOrange text-white py-2 px-4 rounded text-lg drop-shadow-three md-mt-0 mt-4 md-mb-0 mb-4">
                Join Now
              </button>
            </div>
          </div>
        </section>

        <section className="about-section-03 lg:py-20 py-10">
          <div className="max-w-7xl mx-auto px-4 mx-auto">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
              <div className="flex justify-center items-center">
                <img
                  className="w-full h-auto max-w-xs lg:max-w-md"
                  src={image3}
                  alt="Gold Standard Currency Image"
                />
              </div>
              <div className="space-y-6">
                <div className="max-w-7xl mx-auto px-4 section-title">
                  <div className="title-header">
                    <h3 className="text-gray-500 text-xl mb-4">What is</h3>
                    <h2 className="lg:text-6xl text-3xl font-extrabold text-gray-900 mb-12">
                      Real World Application
                    </h2>
                  </div>
                  <div className="mt-6 title-desc">
                    <p className="text-gray-600">
                      In today's rapidly evolving digital landscape, innovative
                      solutions are not just concepts, but powerful tools that
                      have real-world implications. Our platform stands as a
                      prime example of how technology seamlessly integrates into
                      various industries to solve complex challenges and enhance
                      efficiency. Whether it's improving financial transactions,
                      streamlining operations, or providing greater
                      accessibility, the real-world application of our
                      technology empowers individuals and organizations to
                      achieve more with less effort.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!--process-section--> */}
        <section className="bg-black py-16 lg:px-16 px:20">
          <div className="mx-auto px-4">
            {/* <!-- Section Header --> */}
            <div className="text-center mb-14">
              <h3 className="text-orange-400 text-sm font-semibold uppercase">
                BDTCOIN Is Completely Gold Standard Digital Currency.
              </h3>
              <h2 className="text-white text-4xl font-bold mt-2">
                Main Features
              </h2>
            </div>

            {/* <!-- Features Grid --> */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 px-4">
              {/* <!-- Feature Item --> */}
              <div className="relative p-8 border-x-2 border-yellow-950 hover:border-orange-500 ease-in-out duration-300 rounded-lg shadow-lg">
                <span className="top-4 text-8xl font-bold text-stroke">01</span>
                <h3 className="text-xl font-semibold text-white mt-12">
                  Anchored in Gold, Proven by Blockchain
                </h3>
                <p className="text-gray-400 mt-4">
                  Stability powered by tangible assets.
                </p>
              </div>

              {/* <!-- Feature Item --> */}
              <div className="relative p-8 border-x-2 border-yellow-950 hover:border-orange-500 ease-in-out duration-300 rounded-lg shadow-lg">
                <span className="top-4 text-8xl font-bold text-stroke">02</span>
                <h3 className="text-xl font-semibold text-white mt-12">
                  Real-World Value, Digital Freedom
                </h3>
                <p className="text-gray-400 mt-4">
                  Bridging physical and digital finance with RWAs.
                </p>
              </div>

              {/* <!-- Feature Item --> */}
              <div className="relative p-8 border-x-2 border-yellow-950 hover:border-orange-500 ease-in-out duration-300 rounded-lg shadow-lg">
                <span className="top-4 text-8xl font-bold text-stroke">03</span>
                <h3 className="text-xl font-semibold text-white mt-12">
                  Seamless Transactions
                </h3>
                <p className="text-gray-400 mt-4">
                  Empowering secure, peer-to-peer transactions with unmatched
                  efficiency.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* <!--process-section end--> */}

        {/* <!-- padding_top_zero-section --> */}
        <section className="prt-row padding_top_zero-section lg:px-16 px:20 lg:py-28 py-10 clearfix">
          <div className="flex items-center justify-center">
            <div
              className="w-full mx-auto text-center"
              style={{
                maxWidth: "680px",
                marginBottom: "44px",
                margin: "0 auto",
              }}
            >
              <h3 className="text-orange-400 text-sm font-semibold uppercase">
                The presale phase has successfully wrapped up, marking a key
                milestone in our project's journey.
              </h3>
              <h2 className="mb-4 text-3xl font-bold !leading-tight sm:text-4xl md:text-[45px] text-white [text-shadow:0_0_rgba(0,0,0,0.1)]">
                Wrapped Up Pre Sale
              </h2>
              <p className="text-base !leading-relaxed text-body-color md:text-lg"></p>
            </div>
          </div>

          <div className="grid grid-cols-1  lg:grid-cols-4 gap-8 px-4">
            <div className="bg-zinc-900 text-white p-6 rounded-lg shadow-lg">
              <div className="flex justify-between mb-4 items-center">
                <h2 className="text-customOrange text-xl font-bold mb-4 self-end">
                  Phase 1
                </h2>
                <div>
                  <img
                    src={image2}
                    alt=""
                    className="w-16 h-16 rounded-full border border-yellow-500"
                  />
                </div>
              </div>
              <div className="flex justify-between mb-2">
                <div>
                  <p className="text-sm text-slate-500">Token Allocated</p>
                  <p className="text-lg font-bold">6,30,00,00,000</p>
                </div>
                <div className="mb-2 text-right">
                  <p className="text-sm text-slate-500">Percentage</p>
                  <p className="text-2xl font-bold text-white text-right">9%</p>
                </div>
              </div>
              <div className="flex justify-between mb-2">
                <div>
                  <p className="text-sm text-slate-500">Start Date</p>
                  <p className="text-base font-semibold">1 Mar, 2024</p>
                </div>
                <div className="text-right">
                  <p className="text-sm text-slate-500">End Date</p>
                  <p className="text-base font-semibold">18 Mar, 2024</p>
                </div>
              </div>
              <div className="mb-4">
                <p className="text-sm text-slate-500">Token Price</p>
                <p className="text-base font-semibold">0.00007 USD</p>
              </div>
              <div className="flex justify-between mb-2">
                <button className="bg-customOrange text-white font-bold py-1 px-4 rounded-lg hover:bg-yellow-600 h-10">
                  View detail
                </button>
                <div className="mb-4">
                  <p className="text-sm text-slate-500">Status</p>
                  <p className="text-base font-semibold text-lime-600">
                    SUCCESS
                  </p>
                </div>
              </div>
            </div>

            {/* <!-- Feature Item --> */}
            <div className="bg-zinc-900 text-white p-6 rounded-lg shadow-lg">
              <div className="flex justify-between mb-4 items-center">
                <h2 className="text-customOrange text-xl font-bold mb-4 self-end">
                  Phase 2
                </h2>
                <div>
                  <img
                    src={image2}
                    alt=""
                    className="w-16 h-16 rounded-full border border-yellow-500"
                  />
                </div>
              </div>
              <div className="flex justify-between mb-2">
                <div>
                  <p className="text-sm text-slate-500">Token Allocated</p>
                  <p className="text-lg font-bold">6,30,00,00,000</p>
                </div>
                <div className="mb-2 text-right">
                  <p className="text-sm text-slate-500">Percentage</p>
                  <p className="text-2xl font-bold text-white text-right">9%</p>
                </div>
              </div>
              <div className="flex justify-between mb-2">
                <div>
                  <p className="text-sm text-slate-500">Start Date</p>
                  <p className="text-base font-semibold">19 Mar, 2024</p>
                </div>
                <div className="text-right">
                  <p className="text-sm text-slate-500">End Date</p>
                  <p className="text-base font-semibold">06 Jun, 2024</p>
                </div>
              </div>
              <div className="mb-4">
                <p className="text-sm text-slate-500">Token Price</p>
                <p className="text-base font-semibold">0.00007 USD</p>
              </div>
              <div className="flex justify-between mb-2">
                <button className="bg-customOrange text-white font-bold py-1 px-4 rounded-lg hover:bg-yellow-600 h-10">
                  View detail
                </button>
                <div className="mb-4">
                  <p className="text-sm text-slate-500">Status</p>
                  <p className="text-base font-semibold text-lime-600">
                    SUCCESS
                  </p>
                </div>
              </div>
            </div>

            {/* <!-- Feature Item --> */}
            <div className="bg-zinc-900 text-white p-6 rounded-lg shadow-lg">
              <div className="flex justify-between mb-4 items-center">
                <h2 className="text-customOrange text-xl font-bold mb-4 self-end">
                  Phase 3
                </h2>
                <div>
                  <img
                    src={image2}
                    alt=""
                    className="w-16 h-16 rounded-full border border-yellow-500"
                  />
                </div>
              </div>
              <div className="flex justify-between mb-2">
                <div>
                  <p className="text-sm text-slate-500">Token Allocated</p>
                  <p className="text-lg font-bold">6,30,00,00,000</p>
                </div>
                <div className="mb-2 text-right">
                  <p className="text-sm text-slate-500">Percentage</p>
                  <p className="text-2xl font-bold text-white text-right">9%</p>
                </div>
              </div>
              <div className="flex justify-between mb-2">
                <div>
                  <p className="text-sm text-slate-500">Start Date</p>
                  <p className="text-base font-semibold">7 Jun, 2024</p>
                </div>
                <div className="text-right">
                  <p className="text-sm text-slate-500">End Date</p>
                  <p className="text-base font-semibold">10 Aug, 2024</p>
                </div>
              </div>
              <div className="mb-4">
                <p className="text-sm text-slate-500">Token Price</p>
                <p className="text-base font-semibold">0.00007 USD</p>
              </div>
              <div className="flex justify-between mb-2">
                <button className="bg-customOrange text-white font-bold py-1 px-4 rounded-lg hover:bg-yellow-600 h-10">
                  View detail
                </button>
                <div className="mb-4">
                  <p className="text-sm text-slate-500">Status</p>
                  <p className="text-base font-semibold text-lime-600">
                    SUCCESS
                  </p>
                </div>
              </div>
            </div>
            {/* <!-- Feature Item --> */}
            <div className="bg-zinc-900 text-white p-6 rounded-lg shadow-lg">
              <div className="flex justify-between mb-4 items-center">
                <h2 className="text-customOrange text-xl font-bold mb-4 self-end">
                  Phase 4
                </h2>
                <div>
                  <img
                    src={image2}
                    alt=""
                    className="w-16 h-16 rounded-full border border-yellow-500"
                  />
                </div>
              </div>
              <div className="flex justify-between mb-2">
                <div>
                  <p className="text-sm text-slate-500">Token Allocated</p>
                  <p className="text-lg font-bold">6,30,00,00,000</p>
                </div>
                <div className="mb-2 text-right">
                  <p className="text-sm text-slate-500">Percentage</p>
                  <p className="text-2xl font-bold text-white text-right">9%</p>
                </div>
              </div>
              <div className="flex justify-between mb-2">
                <div>
                  <p className="text-sm text-slate-500">Start Date</p>
                  <p className="text-base font-semibold">11 Aug, 2024</p>
                </div>
                <div className="text-right">
                  <p className="text-sm text-slate-500">End Date</p>
                  <p className="text-base font-semibold">25 Oct, 2024</p>
                </div>
              </div>
              <div className="mb-4">
                <p className="text-sm text-slate-500">Token Price</p>
                <p className="text-base font-semibold">0.00007 USD</p>
              </div>
              <div className="flex justify-between mb-2">
                <button className="bg-customOrange text-white font-bold py-1 px-4 rounded-lg hover:bg-yellow-600 h-10">
                  View detail
                </button>
                <div className="mb-4">
                  <p className="text-sm text-slate-500">Status</p>
                  <p className="text-base font-semibold text-lime-600">
                    SUCCESS
                  </p>
                </div>
              </div>
            </div>
            {/* <!-- Feature Item --> */}
            <div className="bg-zinc-900 text-white p-6 rounded-lg shadow-lg">
              <div className="flex justify-between mb-4 items-center">
                <h2 className="text-customOrange text-xl font-bold mb-4 self-end">
                  Phase 5
                </h2>
                <div>
                  <img
                    src={image2}
                    alt=""
                    className="w-16 h-16 rounded-full border border-yellow-500"
                  />
                </div>
              </div>
              <div className="flex justify-between mb-2">
                <div>
                  <p className="text-sm text-slate-500">Token Allocated</p>
                  <p className="text-lg font-bold">6,30,00,00,000</p>
                </div>
                <div className="mb-2 text-right">
                  <p className="text-sm text-slate-500">Percentage</p>
                  <p className="text-2xl font-bold text-white text-right">9%</p>
                </div>
              </div>
              <div className="flex justify-between mb-2">
                <div>
                  <p className="text-sm text-slate-500">Start Date</p>
                  <p className="text-base font-semibold">26 Oct, 2024</p>
                </div>
                <div className="text-right">
                  <p className="text-sm text-slate-500">End Date</p>
                  <p className="text-base font-semibold">15 Nov, 2024</p>
                </div>
              </div>
              <div className="mb-4">
                <p className="text-sm text-slate-500">Token Price</p>
                <p className="text-base font-semibold">0.00007 USD</p>
              </div>
              <div className="flex justify-between mb-2">
                <button className="bg-customOrange text-white font-bold py-1 px-4 rounded-lg hover:bg-yellow-600 h-10">
                  View detail
                </button>
                <div className="mb-4">
                  <p className="text-sm text-slate-500">Status</p>
                  <p className="text-base font-semibold text-lime-600">
                    SUCCESS
                  </p>
                </div>
              </div>
            </div>
            {/* <!-- Feature Item --> */}
            <div className="bg-zinc-900 text-white p-6 rounded-lg shadow-lg">
              <div className="flex justify-between mb-4 items-center">
                <h2 className="text-customOrange text-xl font-bold mb-4 self-end">
                  Phase 6
                </h2>
                <div>
                  <img
                    src={image2}
                    alt=""
                    className="w-16 h-16 rounded-full border border-yellow-500"
                  />
                </div>
              </div>
              <div className="flex justify-between mb-2">
                <div>
                  <p className="text-sm text-slate-500">Token Allocated</p>
                  <p className="text-lg font-bold">6,30,00,00,000</p>
                </div>
                <div className="mb-2 text-right">
                  <p className="text-sm text-slate-500">Percentage</p>
                  <p className="text-2xl font-bold text-white text-right">9%</p>
                </div>
              </div>
              <div className="flex justify-between mb-2">
                <div>
                  <p className="text-sm text-slate-500">Start Date</p>
                  <p className="text-base font-semibold">16 Nov, 2024</p>
                </div>
                <div className="text-right">
                  <p className="text-sm text-slate-500">End Date</p>
                  <p className="text-base font-semibold">30 Nov, 2024</p>
                </div>
              </div>
              <div className="mb-4">
                <p className="text-sm text-slate-500">Token Price</p>
                <p className="text-base font-semibold">0.00007 USD</p>
              </div>
              <div className="flex justify-between mb-2">
                <button className="bg-customOrange text-white font-bold py-1 px-4 rounded-lg hover:bg-yellow-600 h-10">
                  View detail
                </button>
                <div className="mb-4">
                  <p className="text-sm text-slate-500">Status</p>
                  <p className="text-base font-semibold text-lime-600">
                    SUCCESS
                  </p>
                </div>
              </div>
            </div>
            {/* <!-- Feature Item --> */}
            <div className="bg-zinc-900 text-white p-6 rounded-lg shadow-lg">
              <div className="flex justify-between mb-4 items-center">
                <h2 className="text-customOrange text-xl font-bold mb-4 self-end">
                  Phase 7
                </h2>
                <div>
                  <img
                    src={image2}
                    alt=""
                    className="w-16 h-16 rounded-full border border-yellow-500"
                  />
                </div>
              </div>
              <div className="flex justify-between mb-2">
                <div>
                  <p className="text-sm text-slate-500">Token Allocated</p>
                  <p className="text-lg font-bold">6,30,00,00,000</p>
                </div>
                <div className="mb-2 text-right">
                  <p className="text-sm text-slate-500">Percentage</p>
                  <p className="text-2xl font-bold text-white text-right">9%</p>
                </div>
              </div>
              <div className="flex justify-between mb-2">
                <div>
                  <p className="text-sm text-slate-500">Start Date</p>
                  <p className="text-base font-semibold">1 Dec, 2024</p>
                </div>
                <div className="text-right">
                  <p className="text-sm text-slate-500">End Date</p>
                  <p className="text-base font-semibold">20 Dec, 2024</p>
                </div>
              </div>
              <div className="mb-4">
                <p className="text-sm text-slate-500">Token Price</p>
                <p className="text-base font-semibold">0.00007 USD</p>
              </div>
              <div className="flex justify-between mb-2">
                <button className="bg-customOrange text-white font-bold py-1 px-4 rounded-lg hover:bg-yellow-600 h-10">
                  View detail
                </button>
                <div className="mb-4">
                  <p className="text-sm text-slate-500">Status</p>
                  <p className="text-base font-semibold text-lime-600">
                    SUCCESS
                  </p>
                </div>
              </div>
            </div>
            {/* <!-- Feature Item --> */}
            <div className="bg-zinc-900 text-white p-6 rounded-lg shadow-lg">
              <div className="flex justify-between mb-4 items-center">
                <h2 className="text-customOrange text-xl font-bold mb-4 self-end">
                  Phase 8
                </h2>
                <div>
                  <img
                    src={image2}
                    alt=""
                    className="w-16 h-16 rounded-full border border-yellow-500"
                  />
                </div>
              </div>
              <div className="flex justify-between mb-2">
                <div>
                  <p className="text-sm text-slate-500">Token Allocated</p>
                  <p className="text-lg font-bold">6,30,00,00,000</p>
                </div>
                <div className="mb-2 text-right">
                  <p className="text-sm text-slate-500">Percentage</p>
                  <p className="text-2xl font-bold text-white text-right">9%</p>
                </div>
              </div>
              <div className="flex justify-between mb-2">
                <div>
                  <p className="text-sm text-slate-500">Start Date</p>
                  <p className="text-base font-semibold">21 Dec, 2024</p>
                </div>
                <div className="text-right">
                  <p className="text-sm text-slate-500">End Date</p>
                  <p className="text-base font-semibold">31 Dec, 2024</p>
                </div>
              </div>
              <div className="mb-4">
                <p className="text-sm text-slate-500">Token Price</p>
                <p className="text-base font-semibold">0.00007 USD</p>
              </div>
              <div className="flex justify-between mb-2">
                <button className="bg-customOrange text-white font-bold py-1 px-4 rounded-lg hover:bg-yellow-600 h-10">
                  View detail
                </button>
                <div className="mb-4">
                  <p className="text-sm text-slate-500">Status</p>
                  <p className="text-base font-semibold text-lime-600">
                    SUCCESS
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- padding_top_zero-section end --> */}

        {/* <!-- broken-section --> */}

        <section className="py-16 md:py-20 lg:py-28 bg-zinc-900">
          <div className="flex items-center justify-center px-4">
            <div
              className="w-full mx-auto text-center"
              style={{
                maxWidth: "680px",
                marginBottom: "44px",
                margin: "0 auto", // Optional: centers the container horizontally
              }}
            >
              <h2 className="mb-4 text-3xl font-bold !leading-tight sm:text-4xl md:text-[45px] text-white [text-shadow:0_0_rgba(0,0,0,0.1)]">
                ICO - Initial Coin Offering
              </h2>
              <p className="text-base !leading-relaxed text-body-color md:text-lg">
                Investors benefit from the exchange's due diligence, ensuring
                credibility and accessibility.
              </p>
            </div>
          </div>
          <div className="max-w-7xl mx-auto px-4">
            <div className="mx-4 flex flex-wrap items-center">
              <div className="w-full px-4 lg:w-1/3 mx-auto">
                <div
                  className="relative mx-auto mb-12 aspect-[25/24] max-w-[500px] text-center lg:m-0"
                  data-wow-delay=".15s"
                >
                  <img
                    alt="about image"
                    loading="lazy"
                    decoding="async"
                    data-nimg="fill"
                    className="drop-shadow-three dark:hidden dark:drop-shadow-none"
                    src={image2}
                    style={{
                      position: "absolute",
                      height: "100%",
                      width: "100%",
                      inset: "0px",
                      color: "transparent",
                    }}
                  />
                  <img
                    alt="about image"
                    loading="lazy"
                    decoding="async"
                    data-nimg="fill"
                    className="hidden drop-shadow-three dark:block dark:drop-shadow-none"
                    src={image2}
                    style={{
                      position: "absolute",
                      height: "100%",
                      width: "100%",
                      inset: "0px",
                      color: "transparent",
                    }}
                  />
                </div>
              </div>
              <div className="w-full px-4 lg:w-1/2">
                <div className="max-w-[470px]">
                  <div className="mb-4">
                    <h3 className="mb-2 text-xl font-bold text-white dark:text-white sm:text-2xl lg:text-xl xl:text-2xl">
                      <span className="text-customOrange">
                        Number Of Coins For Sale
                      </span>
                    </h3>
                    <p className="text-base font-medium leading-relaxed text-body-color sm:text-lg sm:leading-relaxed">
                      14.2 Million Coins
                    </p>
                  </div>
                  <div className="mb-4">
                    <h3 className="mb-2 text-xl font-bold text-customOrange dark:text-white sm:text-2xl lg:text-xl xl:text-2xl">
                      Sale Price
                    </h3>
                    <p className="text-base font-medium leading-relaxed text-body-color sm:text-lg sm:leading-relaxed">
                      $10/BDT
                    </p>
                  </div>
                  <div className="mb-4">
                    <h3 className="mb-2 text-xl font-bold text-customOrange dark:text-white sm:text-2xl lg:text-xl xl:text-2xl">
                      Minimum/Maximum Buy
                    </h3>
                    <p className="text-base font-medium leading-relaxed text-body-color sm:text-lg sm:leading-relaxed">
                      3 BDT Coins/Unlimited
                    </p>
                  </div>
                  <div className="mb-4">
                    <h3 className="mb-2 text-xl font-bold text-customOrange dark:text-white sm:text-2xl lg:text-xl xl:text-2xl">
                      Sale Ending on
                    </h3>
                    <p className="text-base font-medium leading-relaxed text-body-color sm:text-lg sm:leading-relaxed">
                      25 Jan, 2025
                    </p>
                  </div>

                  <div className="mb-2">
                    <h3 className="mb-2 text-xl font-bold text-customOrange dark:text-white sm:text-2xl lg:text-xl xl:text-2xl">
                      Acceptable Currencies
                    </h3>
                    <p className="text-base font-medium leading-relaxed text-body-color sm:text-lg sm:leading-relaxed">
                      BTC , USDT
                    </p>
                  </div>
                  <div className="mb-1">
                    <h3 className="mb-2 text-xl font-bold text-customOrange dark:text-white sm:text-2xl lg:text-xl xl:text-2xl">
                      Listing Price
                    </h3>
                    <p className="text-base font-medium leading-relaxed text-body-color sm:text-lg sm:leading-relaxed">
                      $22.5
                    </p>
                  </div>
                  <div className="mb-1 border border-white p-2 rounded-sm">
                    <h3 className="mb-2 text-xl font-bold text-customOrange dark:text-white sm:text-2xl lg:text-xl xl:text-2xl">
                      Pre sale ends on
                    </h3>
                    <p className="text-base font-medium leading-relaxed text-body-color sm:text-lg sm:leading-relaxed">
                      Feb 5, 2025
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- broken-section end--> */}

        {/* <!--blog-section--> */}
        <section className="py-16 md:py-20 lg:py-28 bg-gray-950">
          <div className="max-w-7xl mx-auto px-4 flex items-center justify-center">
            <div
              className="w-full px-3 mx-auto text-center"
              style={{
                maxWidth: "570px",
                marginBottom: "44px",
                margin: "0 auto", // Optional: centers the container horizontally
              }}
            >
              <h2 className="mb-0 text-3xl font-bold text-white !leading-tight sm:text-4xl md:text-[45px]  py-2  [text-shadow:0_0_rgba(0,0,0,0.1)] ">
                BDT Tokenomics
              </h2>
              <p className="text-base !leading-relaxed text-white md:text-lg">
                Join the industry leaders to Explore the balanced and
                transparent economy of BDT Token.
              </p>
            </div>
          </div>

          <div className="max-w-7xl mx-auto px-4">
            <div className="mx-4 flex flex-wrap items-center">
              <div className="w-full lg:px-4 px-1 lg:w-1/3 mx-auto">
                <div className="max-w-[470px] text-white">
                  <div className="mb-4 border-2 rounded p-2 border-orange-500">
                    <h3 className="mb-2 text-xl font-bold dark:text-white sm:text-2xl lg:text-xl xl:text-2xl">
                      Total Supply: <br />
                      <span className="text-customOrange">
                        14.2 Million Coins
                      </span>
                    </h3>
                  </div>
                  <div className="mb-4">
                    <h3 className="mb-2 text-xl font-bold text-customOrange dark:text-white sm:text-2xl lg:text-xl xl:text-2xl">
                      Ecosystem
                    </h3>
                    <p className="text-base font-medium leading-relaxed text-body-color sm:text-lg sm:leading-relaxed">
                      20% - 10,0000 Coins
                    </p>
                  </div>

                  <div className="mb-4">
                    <h3 className="mb-2 text-xl font-bold text-customOrange dark:text-white sm:text-2xl lg:text-xl xl:text-2xl">
                      Exchange
                    </h3>
                    <p className="text-base font-medium leading-relaxed text-body-color sm:text-lg sm:leading-relaxed">
                      20% - 10,00000 Coins
                    </p>
                  </div>

                  <div className="mb-4">
                    <h3 className="mb-2 text-xl font-bold text-customOrange dark:text-white sm:text-2xl lg:text-xl xl:text-2xl">
                      Development
                    </h3>
                    <p className="text-base font-medium leading-relaxed text-body-color sm:text-lg sm:leading-relaxed">
                      20% - 10,00000 Coins
                    </p>
                  </div>
                  <div className="mb-4">
                    <h3 className="mb-2 text-xl font-bold text-customOrange dark:text-white sm:text-2xl lg:text-xl xl:text-2xl">
                      Liquidity
                    </h3>
                    <p className="text-base font-medium leading-relaxed text-body-color sm:text-lg sm:leading-relaxed">
                      20% - 10,00000 Coins
                    </p>
                  </div>
                  <div className="mb-1">
                    <h3 className="mb-2 text-xl font-bold text-customOrange dark:text-white sm:text-2xl lg:text-xl xl:text-2xl">
                      Community Airdrop
                    </h3>
                    <p className="text-base font-medium leading-relaxed text-body-color sm:text-lg sm:leading-relaxed">
                      5% - 10,00000 Coins
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full px-4 lg:w-1/2">
                <div
                  className="relative mx-auto mb-12 aspect-[25/24] max-w-[500px] text-center lg:m-0"
                  data-wow-delay=".15s"
                >
                  <img
                    alt="about image"
                    loading="lazy"
                    decoding="async"
                    data-nimg="fill"
                    className="drop-shadow-three dark:hidden dark:drop-shadow-none"
                    src={image4}
                    style={{
                      position: "absolute",
                      height: "100%",
                      width: "100%",
                      inset: "0px",
                      color: "transparent",
                    }}
                  />
                  <img
                    alt="about image"
                    loading="lazy"
                    decoding="async"
                    data-nimg="fill"
                    className="hidden drop-shadow-three dark:block dark:drop-shadow-none"
                    src={image4}
                    style={{
                      position: "absolute",
                      height: "100%",
                      width: "100%",
                      inset: "0px",
                      color: "transparent",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="md:py-5 py-4">
          <div className="flex items-center justify-center">
            <div
              className="w-full mx-auto text-center"
              style={{
                maxWidth: "970px",
                backgroundImage: `url(${image5})`,
                backgroundSize: "cover",
                padding: "30px",
                borderRadius: "10px",
                margin: "0 auto", // Optional: centers the container horizontally
              }}
            >
              <h2 className="mb-0 lg:text-3xl text-2xl leading-10 drop-shadow-lg font-bold text-white sm:text-4xl md:text-[20px] [text-shadow:0_0_rgba(0,0,0,0.1)]">
                Join The BDT Airdrop Campaign today!
                <Link
                  to="/airdrop"
                  className="bg-customOrange text-white py-2 px-4 rounded text-lg drop-shadow-three md-mt-0 mt-4 md-mb-0 mb-4"
                >
                  Click here
                </Link>
              </h2>
            </div>
          </div>
        </section> */}
      </div>
      {/* <!--site - main end-- > */}

      {/* <!--footer start--> */}

      <a id="totop" href="#top">
        <i className="icon-angle-up"></i>
      </a>
      <Footer />
    </div>
  );
}
