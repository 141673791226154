import React, { useEffect, useState } from "react";
import { API_URLS } from "../../Config/apiUrls";
import axiosInstance from "../../Config/axios";
import { Link, useNavigate } from "react-router-dom";
import { useDisconnect } from "wagmi";
import box from "../../images/box.png";
import { toast } from "react-toastify";
import { Dialog } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import TelegramLogin from "../../components/Telegram";

export default function Profile() {
  const [isLoading, setIsLoading] = useState(false);
  const [getUserdata, setUserData] = useState();
  const [taskList, setTaskList] = useState();
  const token = localStorage.getItem("token");
  const { disconnect } = useDisconnect();
  const navigator = useNavigate();
  const [isButton, setIsbutton] = useState(false);
  const [claimlist, setClaimlist] = useState();
  const [open, setOpen] = React.useState(false);
  const handleOpen = (name, email) => {
    setOpen(true);
    setusername(name);
    setEmail(email);
  };
  const handleClose = () => setOpen(false);
  const [username, setusername] = useState();
  const [email, setEmail] = useState();

  const update = () => {
    axiosInstance
      .post(API_URLS.UserUpdateProfile, { fullname: username, email })
      .then((res) => {
        toast.success(res.data.message);
        getTaskSingale();
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTaskSingale = () => {
    setIsLoading(true);
    axiosInstance
      .get(API_URLS.getDashboard)
      .then((res) => {
        setUserData(res.data.userdata);

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (!err.response.data.status) {
          logout();
        }
        setIsLoading(false);
      });
  };

  const claimeList = () => {
    axiosInstance
      .get(API_URLS.getclaimhistory)
      .then((res) => {
        setClaimlist(res.data.claimhistory);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    claimeList();
  }, []);

  const claimPointFunction = (amount) => {
    if (Number(amount) > 20) {
      setIsbutton(true);
      axiosInstance
        .post(API_URLS.claimPoint, { amount: amount })
        .then((res) => {
          toast.success(res.data.message);
          setIsbutton(false);
          getTaskSingale();
          claimeList();
        })
        .catch((err) => {
          console.log(err);
          setIsbutton(false);
        });
    } else {
      toast.warn(
        "If your points are less than 20, you cannot claim your points."
      );
    }
  };

  const getCompletedtaslList = () => {
    axiosInstance
      .get(API_URLS.getTaskhistory)
      .then((res) => {
        setTaskList(res.data.Taskshistory);
      })
      .catch((err) => {
        console.log(err);
        // if (!err.response.data.status) {
        //   logout();
        // }
      });
  };

  const logout = () => {
    disconnect();
    localStorage.removeItem("token");
    navigator("/");
  };

  useEffect(() => {
    if (!token) {
      navigator("/");
    }
  }, [token]);

  useEffect(() => {
    getTaskSingale();
    getCompletedtaslList();
  }, []);
  return (
    <div>
      {isLoading ? (
        <div className="loader-mask">
          <div className="loader">
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <div class="lg:my-24">
          <section class="py-16 lg:py-5 lg:px-16 mx:20 max-w-7xl mx-auto">
            <div class="mx-2.5 my-16">
              <div class="lg:flex block gap-4 my-10">
                <div class="lg:w-1/2 sm:w-full mb-10">
                  <h3 class="text-white text-2xl mb-4">Profile</h3>
                </div>
                <div class="lg:w-1/2 sm:w-full mb-10 lg:text-right">
                  <span class="text-white">
                    Hi,
                    <span class="text-customOrange font-bold ml-10">
                      {getUserdata?.fullname === null
                        ? `${getUserdata?.user_id?.slice(
                            0,
                            4
                          )}...${getUserdata?.user_id?.slice(-6)}`
                        : getUserdata?.fullname}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </section>

          <div class="container mx-auto py-8">
            <div class="grid grid-cols-4 sm:grid-cols-12 gap-6 px-4">
              <div class="col-span-4 sm:col-span-3">
                <div class="card">
                  <div class="lines"></div>
                  <div class="imgBx">
                    <img src="https://cdn-icons-png.flaticon.com/512/149/149071.png" />
                  </div>
                </div>
                <div class="content">
                  <div class="details my-6 text-center">
                    <p>
                      {getUserdata?.fullname ? getUserdata?.fullname : "---"}
                    </p>
                    <span className="flex items-center justify-center">
                      <p>{getUserdata?.email ? getUserdata?.email : "---"}</p>
                      <EditIcon
                        onClick={() =>
                          handleOpen(getUserdata.fullname, getUserdata.email)
                        }
                        className="!text-[100%] pl-2 cursor-pointer"
                      />
                    </span>

                    <div class="bg-zinc-900 hover:bg-zinc-800 p-4 rounded-lg  items-center gap-4">
                      <div className="flex gap-3">
                        <span class="bg-gray-700 lg:w-8 h-8 w-8 flex items-center justify-center rounded-full text-sm">
                          <img
                            src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/x-social-media-logo-icon.png"
                            alt=""
                          />
                        </span>
                        <span>Twitter</span>

                        {getUserdata?.twitterLinkstatus === "0" ? (
                          <span class="ml-auto">
                            <Link
                              to={`https://poolliquidity.aquaeal.in/ApiV3/TwitterAuth/login?UserToken=${token}`}
                              className="bg-customOrange p-2 rounded-full hover:text-white text-black font-bold  cursor-pointer"
                              target="_blank"
                            >
                              Link with X
                            </Link>
                          </span>
                        ) : (
                          <span class="ml-auto bg-green-500 p-2 rounded-xl">
                            <i class="icon icon-check"></i>
                          </span>
                        )}
                      </div>
                    </div>

                    <div class="mt-20 bg-zinc-900 hover:bg-zinc-800 p-4 rounded-lg items-center gap-4">
                      <div className="flex gap-3">
                        <span class="bg-gray-700 lg:w-8 h-8 w-8 flex items-center justify-center rounded-full text-sm">
                          <img
                            src="https://cdn-icons-png.flaticon.com/512/2111/2111646.png"
                            alt=""
                          />
                        </span>
                        <span>Telegram</span>

                        {getUserdata?.TelegramLinkStatus === "0" ? (
                          <span class="ml-auto pl-5">
                            <TelegramLogin />
                          </span>
                        ) : (
                          <span class="ml-auto bg-green-500 p-2 rounded-xl">
                            <i class="icon icon-check"></i>
                          </span>
                        )}
                      </div>
                    </div>

                    <div class="mt-20 bg-zinc-900 hover:bg-zinc-800 p-4 rounded-lg items-center gap-4">
                      <div className="flex gap-3">
                        <span class="bg-gray-700 lg:w-8 h-8 w-8 flex items-center justify-center rounded-full text-sm">
                          <img
                            src="https://cdn-icons-png.flaticon.com/512/3670/3670147.png"
                            alt=""
                          />
                        </span>
                        <span>Youtube</span>

                        {getUserdata?.youtubelinkStatus === "0" ? (
                          <span class="ml-auto">
                            <Link
                              to={`https://poolliquidity.aquaeal.in/ApiV3/SocialLoginAuth/Youtube?UserToken=${token}`}
                              className="bg-customOrange p-2 rounded-full hover:text-white text-black font-bold  cursor-pointer"
                              target="_blank"
                            >
                              Link with youtube
                            </Link>
                          </span>
                        ) : (
                          <span class="ml-auto bg-green-500 p-2 rounded-xl">
                            <i class="icon icon-check"></i>
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-span-4 sm:col-span-9">
                <div class="shadow rounded-lg p-6 border">
                  <h4 class=" text-customOrange">Your Address:</h4>
                  <div class="border p-10 border-slate-700 text-white rounded text-ellipsis overflow-hidden">
                    {getUserdata?.address}
                    <span class="float-end text-white text-sm">
                      <a href="#">Copy</a>
                    </span>
                  </div>

                  <div class="grid gap-8 md:grid-cols-2 lg:gap-12 py-2 mt-12">
                    <a
                      href="#"
                      class="flex flex-col p-6 space-y-6 transition-all duration-500 border border-indigo-100 rounded-lg shadow hover:shadow-xl lg:p-8 lg:flex-row lg:space-y-0 lg:space-x-6"
                    >
                      <div class="flex items-center justify-center w-16 h-16 border_warning rounded-full shadow-inner lg:h-16 lg:w-16">
                        <img
                          src="https://cdn-icons-png.flaticon.com/512/8754/8754417.png"
                          alt=""
                          class="h-8"
                        />
                      </div>
                      <div class="flex-1">
                        <h5 class="mb-3">Point</h5>
                        <h3 class="text-customOrange">
                          {getUserdata?.totalpoint}
                        </h3>

                        {isButton ? (
                          <div
                            className="text-black font-bold border_warning px-5 rounded-md cursor-pointer bg-customOrange  py-1"
                            role="status"
                          >
                            <img
                              src="https://cdn-icons-png.flaticon.com/512/8754/8754417.png"
                              alt=""
                              class="h-10"
                            />
                          </div>
                        ) : (
                          <p
                            onClick={() =>
                              claimPointFunction(getUserdata?.totalpoint)
                            }
                            className="text-black font-bold border text-center px-3 rounded-md my-1 cursor-pointer bg-customOrange"
                          >
                            Claim Points
                          </p>
                        )}
                      </div>
                    </a>
                    <a
                      href="#"
                      class="flex flex-col p-6 space-y-6 transition-all duration-500 border border-indigo-100 rounded-lg shadow hover:shadow-xl lg:p-8 lg:flex-row lg:space-y-0 lg:space-x-6"
                    >
                      <div class="flex items-center justify-center w-16 h-16 border_warning rounded-full shadow-inner lg:h-16 lg:w-16">
                        <img
                          src="https://cdn-icons-png.flaticon.com/512/9425/9425012.png"
                          alt=""
                          class="h-8"
                        />
                      </div>
                      <div class="flex-1">
                        <h5 class="mb-3">Completed Tasks</h5>
                        <h3 class="text-customOrange">
                          You have completed {taskList?.length} tasks.
                        </h3>
                      </div>
                    </a>
                  </div>

                  <div className="flex w-full my-3 gap-5 px-3">
                    <div className="flex flex-col w-[50%]">
                      <h4 class=" text-customOrange my-5">Completed Tasks:</h4>
                      <div class="relative overflow-x-auto w-full">
                        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                          <thead class="text-xs text-customOrange bg-zinc-900 hover:bg-zinc-800 uppercase ">
                            <tr>
                              <th scope="col" class="px-6 py-3">
                                Task Name
                              </th>
                              <th scope="col" class="px-6 py-3">
                                Point
                              </th>
                              <th scope="col" class="px-6 py-3">
                                Image
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {taskList?.map((item) => {
                              return (
                                <tr class="bg-black border-b ">
                                  <th
                                    scope="row"
                                    class="px-6 py-4 font-medium text-white0 whitespace-nowrap text-white"
                                  >
                                    {item.taskTitle}
                                  </th>
                                  <td class="px-6 py-4 text-white">
                                    {item.earnpoint}
                                  </td>
                                  <td class="px-6 py-4 text-white">
                                    <img
                                      src={item.taskimage}
                                      alt="image"
                                      className="h-5 w-7"
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="flex flex-col w-[50%]">
                      <h4 class=" text-customOrange my-5">Claim List:</h4>
                      <div class="relative overflow-x-auto w-full">
                        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                          <thead class="text-xs text-customOrange bg-zinc-900 hover:bg-zinc-800 uppercase ">
                            <tr>
                              <th scope="col" class="px-6 py-3">
                                Point
                              </th>
                              <th scope="col" class="px-6 py-3">
                                BDT Coin
                              </th>
                              <th scope="col" class="px-6 py-3">
                                Status
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {claimlist?.map((item) => {
                              return (
                                <tr class="bg-black border-b ">
                                  <td class="px-6 py-4 text-white">
                                    {item.claim_point}
                                  </td>
                                  <td class="px-6 py-4 text-white">
                                    {item.bdt_coin}
                                  </td>
                                  {item.status === "0" && (
                                    <td class="px-6 py-4 text-white">
                                      Pending
                                    </td>
                                  )}
                                  {item.status === "1" && (
                                    <td class="px-6 py-4 text-white">
                                      Success
                                    </td>
                                  )}
                                  {item.status === "2" && (
                                    <td class="px-6 py-4 text-white">
                                      Rejected
                                    </td>
                                  )}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center justify-end mt-9">
                    <p
                      className="flex bg-customOrange p-2 rounded hover:text-white text-black font-bold  cursor-pointer"
                      onClick={() => logout()}
                    >
                      Logout
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Dialog
            open={open}
            onClose={() => handleClose()}
            PaperProps={{
              sx: {
                bgcolor: "#ffa800", // Optional: to add a fallback color
                color: "white",
                borderRadius: "16px",
                boxShadow: "none",
                backgroundImage: `url(${box})`, // Add the image here
                backgroundSize: "cover", // Ensures the image covers the entire background
                backgroundRepeat: "no-repeat", // Prevents image repetition
                backgroundPosition: "center", // Centers the image
                border: "1px solid #ffa800",
              },
              className: `lg:w-[30%] lg:h-[30%] `,
            }}
          >
            <div className="flex flex-col px-10 py-10">
              <p className="font-bold text-xl text-customOrange">
                Edit Profile
              </p>
              <span className="flex flex-col items-center justify-center my-3 gap-3">
                <input
                  type="text"
                  placeholder="Enter user name"
                  className="!outline-none !border-2 !border-customOrange !bg-transparent  !rounded-xl "
                  value={username}
                  onChange={(e) => setusername(e.target.value)}
                />
                <input
                  type="email"
                  placeholder="Enter email"
                  className="!outline-none !border-2 !border-customOrange !bg-transparent  !rounded-xl "
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </span>
              <span className="flex w-full items-center justify-center">
                <button
                  onClick={() => update()}
                  className="px-4 py-2 text-sm  text-customOrange hover:text-white border-customOrange hover:border-white rounded-md hover:bg-customOrange "
                >
                  Save
                </button>
              </span>
            </div>
          </Dialog>
        </div>
      )}
    </div>
  );
}
