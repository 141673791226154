import React from "react";
import logo from "../../images/logo.png";

export default function Footer() {
  return (
    <footer className="bg-neutral-950 text-white overflow-hidden">
      <div className="relative">
        {/* <!-- Background Layer --> */}
        <div className="absolute inset-0 bg-gray-800"></div>
        {/* <!-- Section Highlight Dot --> */}
        <div className="absolute inset-0 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900"></div>
      </div>

      <div className="py-10">
        <div className="max-w-7xl mx-auto px-4 mx-auto px-6">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
            {/* <!-- About Section --> */}
            <div>
              <div>
                <img src={logo} alt="BDTCOIN" className="mb-4" />
              </div>
              <p>
                BDTCOIN is crafted to serve both private and public sectors,
                catering to a broad spectrum of applications.
              </p>
              <div className="mt-6">
                <h3 className="text-lg font-semibold mb-4">Social Info</h3>
                <div className="flex space-x-4">
                  <a
                    href="#"
                    aria-label="facebook"
                    className="text-white hover:text-gray-400"
                  >
                    <i className="icon-facebook"></i>
                  </a>
                  <a
                    href="#"
                    aria-label="twitter"
                    className="text-white hover:text-gray-400"
                  >
                    <i className="icon-twitter"></i>
                  </a>
                  <a
                    href="#"
                    aria-label="linkedin"
                    className="text-white hover:text-gray-400"
                  >
                    <i className="icon-linkedin"></i>
                  </a>
                </div>
              </div>
            </div>

            {/* <!-- Quick Links --> */}
            <div>
              <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
              <ul className="space-y-2">
                <li>
                  <a href="#" className="hover:underline">
                    Home
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:underline">
                    About Us
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:underline">
                    Company News
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:underline">
                    Projects
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:underline">
                    Case Studies
                  </a>
                </li>
              </ul>
            </div>

            {/* <!-- Our Services --> */}
            <div>
              <h3 className="text-lg font-semibold mb-4">Our Services</h3>
              <ul className="space-y-2">
                <li>
                  <a href="#" className="hover:underline">
                    Mining Sector
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:underline">
                    Project Pricing
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:underline">
                    Client Testimonials
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:underline">
                    Our Philosophy
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:underline">
                    Cost Calculator
                  </a>
                </li>
              </ul>
            </div>

            {/* <!-- Newsletter --> */}
            <div>
              <h3 className="text-lg font-semibold mb-4">Our Newsletter</h3>
              <form className="flex flex-col space-y-4">
                <input
                  type="email"
                  placeholder="Your email address"
                  required
                  className="p-2 border border-gray-700 bg-gray-800 rounded focus:outline-none focus:ring-2 focus:ring-yellow-500"
                />
                <button
                  type="submit"
                  className="py-2 px-4 bg-yellow-500 text-gray-900 font-semibold rounded hover:bg-yellow-400"
                >
                  Subscribe
                </button>
                <div className="flex items-center space-x-2 text-sm">
                  <input
                    type="checkbox"
                    id="cookies-consent1"
                    className="h-4 w-4"
                  />
                  <label for="cookies-consent1">
                    I agree that submitted data is being collected and stored.
                  </label>
                </div>
              </form>
              <div className="mt-6">
                <p className="text-sm">
                  <a href="mailto:support@bdtcoin.co" className="underline">
                    support@bdtcoin.co
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-black py-4">
        <div className="max-w-7xl mx-auto px-4 mx-auto px-6">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <ul className="flex space-x-4 text-sm">
              <li>
                <a href="#" className="hover:underline">
                  Help
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline">
                  Terms & Conditions
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline">
                  Procurement
                </a>
              </li>
            </ul>
            <p className="text-sm mt-4 md:mt-0">Copyright © 2024 BDTCOIN</p>
          </div>
        </div>
      </div>
    </footer>
    // </div>
  );
}
