import { Divider } from "@mui/material";
import React from "react";

const Task = () => {
  return (
    <div className="min-h-screen bg-black ">
      {/* Header Section */}
      <div className="relative bg-blue-500 h-[60vh]">
        <div className="absolute inset-0">
          <img
            src="https://poolliquidity.aquaeal.in/assets/backend/projectbanner/airdropbg.jpg"
            alt="Background"
            className="w-full h-full object-cover"
          />
        </div>
        {/* <div className="absolute inset-0 bg-customOrange opacity-50"></div> */}
        <div className="absolute inset-0 max-w-7xl mx-auto px-6  flex justify-between mt-[25%]  items-center">
          <div>
            <p className="text-customOrange text-2xl font-bold">
              BDT Coin Ecosystem Partners
            </p>
            <p className="text-customOrange">BDT coin Ecosystem Partners</p>
          </div>
          <div className="flex flex-col items-center">
            <p className="text-customOrange  font-bold">Balance</p>
            <div className="text-customOrange flex items-center gap-2 font-bold">
              <p> 0 </p>
              <img
                src="https://snagsolutions.io/cdn-cgi/image/width=40/https://snag-assets.s3.us-west-2.amazonaws.com/organizations/8f48e0f1-f648-4b0e-99be-3a3c25597a97/websites/d88e4c28-d8cc-45ff-8cff-1180cdc1e87c/images/Satoshi-Currency.png"
                alt=""
                className="w-4 h-4"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Main Section */}
      <div className="max-w-7xl mx-auto px-6 py-10 page">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Left Section */}
          <div className="md:col-span-2  rounded-lg  p-6">
            <h2 className="text-xl font-bold text-customOrange mb-4">
              BDT coin Ecosystem Hub
            </h2>
            <p className="text-customOrange mb-6">BDT coin Network</p>
            {/* <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded-md mb-6">
              Great job! <strong>5 🍂</strong> added to your balance.
            </div> */}

            {/* Task Cards */}
            <div className="space-y-4">
              <div className="flex items-center justify-between p-4 border border-customOrange rounded-lg bg-black shadow">
                <div className="flex items-center">
                  <div className="w-20 h-10 gap-2 flex items-center justify-center bg-orange-100 text-orange-600 font-bold rounded-full mr-4">
                    5
                    <img
                      src="https://snagsolutions.io/cdn-cgi/image/width=40/https://snag-assets.s3.us-west-2.amazonaws.com/organizations/8f48e0f1-f648-4b0e-99be-3a3c25597a97/websites/d88e4c28-d8cc-45ff-8cff-1180cdc1e87c/images/Satoshi-Currency.png"
                      alt=""
                      className="w-4 h-4"
                    />
                  </div>
                  <div className="flex flex-col">
                    <p className="text-customOrange font-semibold">
                      X (Twitter)
                    </p>
                    <p className="text-gray-200">
                      Add the ⛺ emoji and text to your X profile
                    </p>
                    <p className="text-gray-400 text-sm">
                      Add the emoji and hashtag ⛺ #camper4life to your
                      Twitter/X bio
                    </p>
                  </div>
                </div>
                <button className="px-4 py-2 text-sm  text-customOrange border-customOrange rounded-md ">
                  Go to X
                </button>
              </div>

              <div className="flex items-center justify-between p-4 border border-customOrange rounded-lg bg-black shadow">
                <div className="flex items-center">
                  <div className="w-20 h-10 gap-2 flex items-center justify-center bg-orange-100 text-orange-600 font-bold rounded-full mr-4">
                    5
                    <img
                      src="https://snagsolutions.io/cdn-cgi/image/width=40/https://snag-assets.s3.us-west-2.amazonaws.com/organizations/8f48e0f1-f648-4b0e-99be-3a3c25597a97/websites/d88e4c28-d8cc-45ff-8cff-1180cdc1e87c/images/Satoshi-Currency.png"
                      alt=""
                      className="w-4 h-4"
                    />
                  </div>
                  <div className="flex flex-col">
                    <p className="text-customOrange font-semibold">
                      X (Twitter)
                    </p>
                    <p className="text-gray-200">
                      Add the ⛺ emoji and text to your X profile
                    </p>
                    <p className="text-gray-400 text-sm">
                      Add the emoji and hashtag ⛺ #camper4life to your
                      Twitter/X bio
                    </p>
                  </div>
                </div>
                <button className="px-4 py-2 text-sm  text-customOrange border-customOrange rounded-md ">
                  Go to X
                </button>
              </div>
            </div>
          </div>

          {/* Right Section - Leaderboard */}
          <div className=" rounded-lg  p-6">
            <h2 className="text-xl font-bold text-customOrange mb-4">
              Leaderboard
            </h2>
            <div className="flex flex-col">
              {[1, 2, 3, 4, 5, 6].map(() => {
                return (
                  <div>
                    <div className="flex w-full justify-between text-customOrange items-center my-3">
                      <div className="flex gap-10 items-center">
                        <img
                          src="https://snagsolutions.io/cdn-cgi/image/width=32/https://admin.snagsolutions.io/icons/figma/first-award.png"
                          alt=""
                          className="h-8 w-8"
                        />
                        <div className="flex gap-5 items-center">
                          <img
                            src="https://snag-assets.s3.us-west-2.amazonaws.com/users/5df86a30-5a92-4331-a686-0e2df3fb2afc/profiles/copy-of-yellow-minimalist-round-shaped-cafe-logo.jpg"
                            alt=""
                            className="h-8 w-8 rounded-full"
                          />
                          <div className="flex flex-col text-sm">
                            <p className="text-customOrange font-semibold">
                              Shivam
                            </p>
                            <p className="text-gray-400">address</p>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center gap-3">
                        <p className="text-customOrange font-bold">3.5k</p>
                        <img
                          src="https://snagsolutions.io/cdn-cgi/image/width=40/https://snag-assets.s3.us-west-2.amazonaws.com/organizations/8f48e0f1-f648-4b0e-99be-3a3c25597a97/websites/d88e4c28-d8cc-45ff-8cff-1180cdc1e87c/images/Satoshi-Currency.png"
                          alt=""
                          className="w-4 h-4"
                        />
                      </div>
                    </div>
                    <Divider color="customOrange" />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Task;
