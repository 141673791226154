import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoginFunction from "../../utils/LoginFunction";
import logo from "../../images/logo.png";

export default function Header() {
  const navgate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev); // Toggle menu visibility
  };
  return (
    // <div className="page">
    <header className="bg-black text-white absolute bg-opacity-40 z-10 w-full">
      <nav className="max-w-7xl mx-auto  py-10 px-4 flex items-center justify-between">
        <p
          onClick={() => navgate("/")}
          className="text-2xl font-semibold cursor-pointer"
        >
          <img src={logo} alt="Logo" className=" h-10" />
        </p>

        <div className="hidden md:flex space-x-6">
          <a href="#" className="hover:text-gray-400">
            Home
          </a>
          <a href="#" className="hover:text-gray-400">
            About Us
          </a>
          <a href="#" className="hover:text-gray-400">
            Blog
          </a>
          <a href="#" className="hover:text-gray-400">
            Contact Us
          </a>
          <LoginFunction />
        </div>
        {/* <button
          id="mobile-menu-toggle"
          onClick={toggleMenu}
          className="p-2 bg-gray-200 rounded-md"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </button> */}
        <button
          className="md:hidden text-white focus:outline-none"
          id="mobile-menu-toggle"
          onClick={toggleMenu}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </button>
      </nav>
      {/* <div
        id="mobile-menu"
        className={`${
          isMenuOpen ? "block" : "hidden"
        } mt-2 bg-white border rounded shadow-lg`}
      >
        <ul>
          <li className="p-2 border-b">Menu Item 1</li>
          <li className="p-2 border-b">Menu Item 2</li>
          <li className="p-2">Menu Item 3</li>
        </ul>
      </div> */}
      <div className={`${isMenuOpen ? "block" : "hidden"} `} id="mobile-menu">
        <div className="flex flex-col items-center space-y-4 p-5 bg-neutral-950">
          <a href="#" className="text-white hover:text-gray-400">
            Home
          </a>
          <a href="#" className="text-white hover:text-gray-400">
            About Us
          </a>
          <a href="#" className="text-white hover:text-gray-400">
            Blog
          </a>
          <a href="#" className="text-white hover:text-gray-400">
            Contact Us
          </a>
          <LoginFunction />
        </div>
      </div>
    </header>
    // </div>
  );
}
