import React from "react";
import Sidebar from "../components/Sidebar";
export default function Layout({ component }) {
  return (
    <div className="page flex flex-col  ">
      <div className=" flex justify-center ">
        <Sidebar />
      </div>
      <div className="overflow-x-auto  ">{component}</div>
    </div>
  );
}
