// import { LoginButton } from "@telegram-auth/react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axiosInstance1 from "../../Config/axios1";
import { API_URLS } from "../../Config/apiUrls";
import TelegramLoginButton from "telegram-login-button";
import axiosInstance from "../../Config/axios";
import { useNavigate } from "react-router-dom";

export default function TelegramLogin() {
  const [teligramData, setTelegramData] = useState();
  const navigate = useNavigate();

  const AddProject = () => {
    const formData = new FormData();
    formData.append("telegram_id", teligramData?.id);
    formData.append("firstname", teligramData?.first_name);
    formData.append("username", teligramData?.username);
    formData.append("photo_url", teligramData?.photo_url);
    formData.append("auth_date", teligramData?.auth_date);
    formData.append("hash", teligramData?.hash);
    axiosInstance
      .post(API_URLS.TelegramLogin, formData)
      .then((res) => {
        toast.success(res?.data?.message);
        navigate("/airdrop");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    teligramData && AddProject();
  }, [teligramData]);

  return (
    <div className="xbutton">
      <TelegramLoginButton
        botName="moc_crypto_bot"
        dataOnauth={(data) => {
          setTelegramData(data);
        }}
        style={{
          background: "#54a9eb",
          fontSize: ".875rem",
          borderRadius: "0.3rem",
          color: "#fff",
          padding: "7px 16px",
          fontWeight: "600",
          lineHeight: "1.25rem",
        }}
      />
    </div>
  );
}
