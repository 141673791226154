import {
  useAppKit,
  useAppKitAccount,
  useDisconnect,
} from "@reown/appkit/react";
import React, { useEffect, useState } from "react";
import axiosInstance from "../Config/axios";
import { API_URLS } from "../Config/apiUrls";
import axios from "axios";
import { useAccount, useSignMessage } from "wagmi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function LoginFunction() {
  const { open } = useAppKit();
  const { address, isConnected, isDisconnected } = useAccount();
  const { disconnect } = useDisconnect();
  const [nonce, setNonce] = useState();
  const token = localStorage.getItem("token");
  const navigator = useNavigate();

  const { data: signMessageData, signMessage } = useSignMessage();

  const getNonceFunction = () => {
    axiosInstance
      .get(API_URLS.getNonce)
      .then((res) => {
        signMessage({ message: res.data.nonce });
        setNonce(res.data.nonce);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const signWithNonce = () => {
    getNonceFunction();
  };

  const UserSignup = () => {
    axios({
      url: `https://poolliquidity.aquaeal.in/ApiV3/UserApi/walletlogin`,
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-API-KEY": "MOC!#123",
      },
      data: { address: address, message: nonce, signature: signMessageData },
    })
      .then((res) => {
        toast.success(res.data.message);
        navigator("/airdrop");
        localStorage.setItem("token", res.data.token);
      })
      .catch((err) => {
        toast.warn(err.response?.data?.message);
      });
  };

  const logout = () => {
    disconnect();
    localStorage.removeItem("token");
  };

  useEffect(() => {
    if (signMessageData) {
      UserSignup();
    }
  }, [signMessageData]);

  return (
    <div>
      {isConnected ? (
        token ? (
          <div
            onClick={() => navigator("/airdrop")}
            className="flex bg-customOrange p-2 rounded hover:text-black -mt-2 cursor-pointer"
          >
            <span class="ml-auto">
              <i class="icon icon-user"></i>
            </span>
            <p>
              {address?.slice(0, 4)}...
              {address?.slice(-6)}
            </p>
          </div>
        ) : (
          <p
            className="bg-customOrange p-2 rounded hover:text-black -mt-2 cursor-pointer"
            onClick={() => signWithNonce()}
          >
            Sign with Wallet
          </p>
        )
      ) : (
        <p
          className="bg-customOrange p-2 rounded hover:text-black -mt-2 cursor-pointer"
          onClick={() => open()}
        >
          Connect Wallet
        </p>
      )}
    </div>
  );
}
