import Airdrop from "../Pages/Airdrop";
import Profile from "../Pages/Profile";
import Taskdetail from "../Pages/Taskdetail";
import Task from "../Pages/Test";

const routes = [
  {
    id: 1,
    path: "/profile",
    element: <Profile />,
  },
  {
    id: 2,
    path: "/airdrop",
    element: <Airdrop />,
  },
  {
    id: 3,
    path: "/task-detail/:id",
    element: <Taskdetail />,
  },
  {
    id: 3,
    path: "/test",
    element: <Task />,
  },
];
export default routes;
